<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save Client')}}</button>
    </div>
    <div class="client-infomration">
      <el-card class="box-card client-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('Client Information') }}</span>
        </div>
        <el-form class="client-form" ref="client-form" :model="form" label-width="150px" label-position="left">
          <div class="create-client-form">
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Name')" style="width: 100%">
                <el-input v-model="form.clientName"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                <el-input v-model="form.clientEmail"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                <el-input v-model="form.clientPhone"></el-input>
              </el-form-item>
            </div>
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Client Address')" style="width: 100%">
                <el-input v-model="form.clientAddress"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';

export default {
  name: 'ClientForm',
  data(){
    //Default form value
    let form = {
      clientName: '',
      clientEmail: '',
      clientPhone: '',
      clientAddress: '',
    };

    return {
      form,
    };
  },
  methods:{
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    save(){
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentClient': function(){
      this.form = {
        clientName: this.currentClient.client_name,
        clientEmail: this.currentClient.client_email,
        clientPhone: this.currentClient.client_phone,
        clientAddress: this.currentClient.client_address,
      };
    },
  },
  computed: mapState({
    currentClient: state => state.account.currentClient,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.client{
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .client-form{
    padding-left: 20px;
  }
  .el-card__header{
    background-color: black;
    color: white;
  }
  .el-form-item.email{
    margin-right: 30px;
  }
  .client-orders-card{
    margin-top: 50px;
  }
}
</style>
